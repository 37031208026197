// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker hue.
$asp-web-app-primary: mat.define-palette(mat.$indigo-palette);
$asp-web-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$asp-web-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$asp-web-app-theme: mat.define-light-theme((
  color: (
    primary: $asp-web-app-primary,
    accent: $asp-web-app-accent,
    warn: $asp-web-app-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

// Include theme styles for core and each component used in your app.
@include mat.all-component-themes($asp-web-app-theme);
@include mat.all-legacy-component-themes($asp-web-app-theme);

.asp-app-root {
  @include mat.all-component-colors($asp-web-app-theme);
  @include mat.all-legacy-component-colors($asp-web-app-theme);

  /* -----------------------------------------------------------
/* -           overrirde angular material styles             -
/* ----------------------------------------------------------- */

  // overrirde angular material styles
  .mat-option-text {
    font-family: var(--font-family);
    font-weight: normal;
  }

  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background-color: var(--dashboard-telematics-alert-bg);
  }

  .mat-checkbox {
    padding-top: 4px;
  }
  .mat-checkbox[disableripple] .mat-ripple .mat-ripple-element {
    opacity: 0;
  }
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: var(--secondary-color);
    border-radius: 4px;
  }
  .mat-checkbox-frame {
    border-color: rgba(14, 19, 25, 0.8) !important;
    border-radius: 4px !important;
  }
  .mat-checkbox-checkmark-path {
    stroke-width: 3px !important;
  }
  .mat-expansion-indicator {
    padding-bottom: 4px;
  }

  .mat-expansion-panel-content {
    background-color: #f5f6f8;
  }
  .mat-expansion-panel-body {
    padding: 2% 4% 2% 4% !important;
  }
  .service-content .mat-expansion-panel {
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid rgba(14, 19, 25, 0.12);
    :first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
  .service-content .mat-form-field-wrapper {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .service-content .mat-expansion-panel:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .service-content .mat-expansion-panel:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .mat-expansion-panel-header {
    min-height: 48px;
    height: auto !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    position: relative;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    & input:first-of-type {
      transform: translateY(-25%) !important;
    }
    & mat-select:first-of-type {
      transform: translateY(-25%) !important;
    }
    mat-select .mat-select-arrow-wrapper {
      transform: none;
    }
  }

  // Media queries

  // mat-menu
  @media screen and (max-width: 600px) {
    .mat-menu-panel {
      padding-bottom: 10px;
    }
  }

  /* To make the required '*' red color in mat form fields */
  span.mat-placeholder-required.mat-form-field-required-marker {
    color: red;
  }
}